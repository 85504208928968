<template>
  <div>
    <!-- ==== DATA KARTU STOCK ==== -->
    <div class="title text--secondary mb-2">Data Kartu Stok</div>
    <!-- <Export
      :disabled="form.product_id === '' || form.date === '' ? true : false"
      class="mb-3"
      :product_id="form.product_id"
      :start_date="form.date.start"
      :end_date="form.date.end"
      :file_type="'xls'"
      :btnText="'Export Excel'"
      :btnIcon="'mdi-microsoft-excel'"
      :btnColor="'teal'"
      :url="'stock-card/export'"
      :title="'Data Kartu Stok'"
      ref="base-export"
      v-on:onClickExport="responseExport"
    /> -->
    <v-row>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <v-autocomplete
          :items="data_store"
          item-value="store_id"
          item-text="store_name"
          outlined
          dense
          color="#d31145"
          label="Pilih Outlet"
          v-model="form.store_id"
          v-on:change="fetchProduk"
          required>
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.store_name }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <v-autocomplete
          single-line
          :items="arr_Product"
          item-value="name"
          item-text="name"
          outlined
          dense
          color="#d31145"
          label="Pilih Produk"
          return-object
          v-model="form.product"
          v-on:change="changeProduct"
          required>
          <template v-slot:item="data">
            <!-- <template> -->
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.name }} <strong>( {{ data.item.type == 'ingredient' ? 'Bahan baku' : data.item.type == 'package' ? 'Paket' : 'Produk' }} )</strong>
                </v-list-item-title>
              </v-list-item-content>
            <!-- </template> -->
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <VueCtkDateTimePicker
          v-model="form.date"
          label="Pilih Tanggal"
          :range="true"
          noClearButton
          color="#d31145"
          format="YYYY-MM-DD"
          formatted="LL"
          locale="id"
          :max-date="hari"
          :custom-shortcuts="[
            { key: 'today', label: 'Hari ini', value: 'day' },
            { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
            { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
            { key: 'last7Days', label: '7 hari terakhir', value: 7 },
            { key: 'last30Days', label: '30 hari terakhir', value: 30 },
            { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
            { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
            { key: 'thisYear', label: 'Tahun ini', value: 'year' },
            { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
          ]"
        />
      </v-col>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <v-autocomplete
          single-line
          :items="[
            {
              text: 'Stok Masuk',
              value: 'Masuk'
            },
            {
              text: 'Stok Keluar',
              value: 'Keluar'
            },
          ]"
          item-value="value"
          item-text="text"
          outlined
          dense
          color="#d31145"
          label="Pilih Tipe Stok"
          v-model="form.movement_type">
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <v-autocomplete
          single-line
          :items="[
            {
              text: 'Pembelian',
              value: 'Pembelian'
            },
            {
              text: 'Penyesuaian',
              value: 'Penyesuaian'
            },
            {
              text: 'Penjualan',
              value: 'Penjualan'
            },
            {
              text: 'Transfer',
              value: 'Transfer'
            },
            {
              text: 'Konversi',
              value: 'Konversi'
            },
            {
              text: 'Reset',
              value: 'Reset'
            }
          ]"
          item-value="value"
          item-text="text"
          outlined
          dense
          color="#d31145"
          label="Pilih Tipe Transaksi"
          v-model="form.transaction_type"
          required>
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" :class="$vuetify.breakpoint.name === 'xs' ? 'py-0' : ''">
        <v-btn
          color="#d31145"
          width="100%"
          :disabled="
            form.product === '' || process.run || form.date === ''
              ? (process.disabled = true)
              : (process.disabled = false || process.export)
          "
          class="white--text"
          @click="fetch"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>
    <div class="my-0">
      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <div
        v-show="!process.run && Object.keys(productDetail).length < 1">
        <Empty
          v-model="contentEmpty"
        />
      </div>

      <v-card-text class="laporan" style="min-height: calc(100vh - 350px); overflow: auto;">
        <v-row v-if="Object.keys(productDetail).length > 0">
          <v-col cols="12" md="6">
            <!-- ==== INFORMASI PRODUK ==== -->
            <v-card flat>
              <div class="text-h6 font-weight-regular mb-5 text-second">
                Informasi Produk
              </div>
              <v-simple-table class="px-3">
                <template>
                  <tbody>
                    <tr>
                      <th class="text-second pl-0" width="30%">Nama Produk</th>
                      <td width="5%" class="text-center text-second">:</td>
                      <td class="text-right text-second">
                        {{ productDetail.name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">Kategori</th>
                      <td width="5%" class="text-center text-second">:</td>
                      <td class="text-right text-second">
                        {{ productDetail.category_name }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">Kode Produk</th>
                      <td width="5%" class="text-center text-second">:</td>
                      <td class="text-right text-second">
                        {{ productDetail.code }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">Tipe Produk</th>
                      <td width="5%" class="text-center text-second">:</td>
                      <td class="text-right text-second">
                        {{ productDetail.type == "ingredient" ? "Bahan baku" : productDetail.type == 'package' ? "Paket" : "Produk" }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <!-- ==== RINGKASAN PERSEDIAAN ==== -->
            <v-card flat>
              <div class="text-h6 font-weight-regular mb-5 text-second">
                Ringkasan Persediaan
              </div>
              <v-simple-table class="px-3">
                <template>
                  <tbody>
                    <tr>
                      <th class="text-second pl-0" width="30%">Total Masuk</th>
                      <td width="5%" class="text-center">:</td>
                      <td class="text-right">
                        {{ productDetail.stock.in | num_format }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">Total Keluar</th>
                      <td width="5%" class="text-center">:</td>
                      <td class="text-right">
                        {{ productDetail.stock.out | num_format }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">Stok Sebelum</th>
                      <td width="5%" class="text-center">:</td>
                      <td class="text-right">
                        {{ productDetail.stock.last | num_format }}
                      </td>
                    </tr>
                    <tr>
                      <th class="text-second pl-0" width="30%">
                        Stok Sekarang
                      </th>
                      <td width="5%" class="text-center">:</td>
                      <td class="text-right">
                        {{ productDetail.stock.current | num_format }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <!-- ==== COMPONENT DATA KOSONG ==== -->
        <Empty v-model="contentEmpty" v-show="emptyData" />

        <!-- ==== HISTORY KARTU STOK ==== -->
        <div
          v-if="!process.run && results.length > 0"
          class="text-h6 font-weight-regular mb-4 mt-5 text-second"
        >
          History Kartu Stok Produk
        </div>
        <!-- <v-simple-table class="mt-3 laporan" fixed-header>
          <template v-slot:default>
            <thead v-if="!process.run && results.length > 0">
              <tr>
                <th class="body-1 font-weight-bold text--secondary text-center pl-0">Tanggal</th>
                <th class="body-1 font-weight-bold text--secondary text-center">Kode Transaksi</th>
                <th class="body-1 font-weight-bold text--secondary text-right">Stok Masuk</th>
                <th class="body-1 font-weight-bold text--secondary text-right">Stok Keluar</th>
                <th class="body-1 font-weight-bold text--secondary text-right">Stok Sebelum</th>
                <th class="body-1 font-weight-bold text--secondary text-right">Stok Sekarang</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(item, index) in results" 
                :key="index">
                <td class="text-center text-second pl-0">{{ item.stock_log_date | datetimeFull }}</td>
                <td class="text-center text-second">{{ item.stock_log_code }}</td>
                <td class="text-right text-second">{{ item.stock_log_qty_in | num_format }}</td>
                <td class="text-right text-second">{{ item.stock_log_qty_out | num_format }}</td>
                <td class="text-right text-second">{{ item.stock_log_previous_balance | num_format }}</td>
                <td class="text-right text-second">{{ item.stock_log_qty_balance | num_format }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider class="mt-2"></v-divider> -->
        <!-- <div v-if="results.length > 0"> -->

        <!-- ====BASE TABLE ====-->
        <base-table
          v-show="displayBaseTable"
          customHeight="auto"
          :isExternalFilterButton="true"
          ref="base-table"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          retrieveDataURL="franchise/stock/history"
          v-on:onMounted="init"
          v-on:onRetrieveDataEnd="detailStockCard"
        >
          <!-- <template v-slot:item="{ item }">
            <tr>
              <td class="text-center text-second pl-0">
                {{ item.stock_log_date | datetimeFull }}
              </td>
              <td class="text-center text-second">{{ item.stock_log_code }}</td>
              <td class="text-right text-second">
                {{ item.stock_log_qty_in | num_format }}
              </td>
              <td class="text-right text-second">
                {{ item.stock_log_qty_out | num_format }}
              </td>
              <td class="text-right text-second">
                {{ item.stock_log_previous_balance | num_format }}
              </td>
              <td class="text-right text-second">
                {{ item.stock_log_qty_balance | num_format }}
              </td>
            </tr>
          </template> -->
          <template v-slot:[`item.input_datetime`]="{ item }">
            {{ item.input_datetime | datetimeFull }}
          </template>
          <template v-slot:[`item.code`]="{ item }">
            {{ item.code }}
          </template>
          <template v-slot:[`item.qty_in`]="{ item }">
            {{ item.qty_in | num_format }}
          </template>
          <template v-slot:[`item.qty_out`]="{ item }">
            {{ item.qty_out | num_format }}
          </template>
          <template v-slot:[`item.balance_before`]="{ item }">
            {{ item.balance_before | num_format }}
          </template>
          <template v-slot:[`item.balance_after`]="{ item }">
            {{ item.balance_after | num_format }}
          </template>
          <!-- ====END BASE TABLE====-->
        </base-table>
      </v-card-text>
    </div>
  </div>
</template>
<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import Export from "@/components/Export";
export default {
  data() {
    return {
      /**
       * DATA COMPONENT BASETABLE
       */
      arr_Product: [],
      singleSelect: true,
      displayBaseTable: false,
      emptyData: false,
      headers: [
        {
          text: "Tanggal",
          sortable: true,
          width: "100",
          value: "input_datetime",
          value: "input_datetime",
          align: "center",
        },
        {
          text: "Kode",
          sortable: true,
          width: "50",
          value: "code",
          value: "code",
          align: "center",
        },
        {
          text: "Stok Masuk",
          sortable: true,
          width: "50",
          value: "qty_in",
          value: "qty_in",
          align: "end",
        },
        {
          text: "Stok Keluar",
          width: "100",
          align: "end",
          sortable: true,
          value: "qty_out",
          value: "qty_out",
        },
        {
          text: "Stok Sebelum",
          width: "50",
          align: "end",
          sortable: true,
          value: "balance_before",
          value: "balance_before",
        },
        {
          text: "Stok Sekarang",
          width: "100",
          align: "end",
          sortable: true,
          value: "balance_after",
          value: "balance_after",
        },
      ],
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      /**
       * END DATA COMPONENT BASETABLE
       */
      list: [],
      selected: {
        pagination: 0,
        menu: 0,
      },
      pagination: {
        data: {},
        page: "",
      },
      contentEmpty: "",
      limit: 10,
      page: 1,
      productDetail: {},
      stockDetail: {},
      results: [],
      options: {},
      submit: false,
      hari: new Date().toISOString().substr(0, 10),
      form: {
        product: "",
        variant_id: "",
        date: {},
        product:{},
        store_id: "",
        movement_type: "",
        transaction_type: "",
      },
      process: {
        form: false,
        run: false,
        export: false,
      },
      response: "",
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  watch: {
    options: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      this.fetch();
    }, 500),
    "selected.menu": function(val) {
      this.selected.menu = val === undefined ? 0 : this.selected.menu;

      this.fetch();
    },
    "selected.pagination": function(val) {
      this.fetch(val + 1);
    },
  },
  created() {
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
    data_store() {
      return JSON.parse(localStorage.getItem('user_store'));
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.fetchProduk()
  },
  methods: {
    /**
     * HANDLE EXPORT
     */
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END HANDLE EXPORT
     */

    /**
     * DISABLE RETRIEVEDATA
     */
    init() {
      this.$refs["base-table"].disableRetrieveData = true;
    },
    /**
     * END DISABLE RETRIEVEDATA
     */

    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    detailStockCard(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      // this.productDetail = res.data.product_detail;
      // this.stockDetail = res.data.stock_detail;
      this.contentEmpty = "Data tidak ditemukan";
    },

    changeProduct(){
      if(this.form.product.id !== undefined){
        this.form.id = this.form.product.id
        this.form.variant_id = this.form.product.variant_id
      }
    },

    async getSummaryHistory () {
      const res = await get(`franchise/stock/detail`, {
        params: {
          store_id: this.form.store_id,
          product_id: this.form.id,
          product_variant_id: this.form.variant_id,
          start_date: this.form.date.start,
          end_date: this.form.date.end,
          movement_type: this.form.movement_type,
          transaction_type: this.form.transaction_type
        }
      });

      let response = res.data

      if (response.status === 200) {
        this.productDetail = response.results.data;
      }
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */

    /**
     * FETCH DATATABLE
     */
    fetch() {
      if (this.form.product && this.form.date) {
        this.$refs["base-table"].disableRetrieveData = false;
        this.$refs["base-table"].params.store_id = this.form.store_id;
        this.$refs["base-table"].params.product_id = this.form.id;
        this.$refs["base-table"].params.product_variant_id = this.form.variant_id;
        this.$refs["base-table"].params.start_date = this.form.date.start;
        this.$refs["base-table"].params.end_date = this.form.date.end;
        this.$refs["base-table"].params.movement_type = this.form.movement_type;
        this.$refs["base-table"].params.transaction_type = this.form.transaction_type;
        this.$refs["base-table"].retrieveData();

        this.getSummaryHistory()
      }
    },
    /**
     * END FETCH DATATABLE
     */

    async fetchProduk(store_id) {
      this.process.run = true;
      await get(`franchise/store/product_product_variant`, {
        params: {
          store_id: this.form.store_id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.process.run = false;
            this.arr_Product = res.results.data;
          }
          this.process.run = false;
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },

  },
};
</script>

<style>
.line:hover {
  border-left: 6px solid #d31145;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s;
}
.background-cols {
  background-color: #ffca8e61;
  border: 3px solid #ffc58461;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.pagination {
  position: fixed;
  bottom: 65px;
}
</style>
